<template>
  <Boxcheck />
</template>

<script>
import Boxcheck from "../../../components/factory_boxcheck";

export default {
  name: "factory_boxcheck",
  components: {
    Boxcheck,
  },
};
</script>